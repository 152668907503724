.add-border {
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    border-radius: 10px 0 0 10px;
  }
  
  .header-border {
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .header-border-start{
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 10px 0 0 10px;
  }
  
  .item-border {
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .item-border-start{
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 10px 0 0 10px;
  }
  
  .item-border:hover {
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .item-border-stack{
    margin-right: 2px;
  }
  .item-row:hover{
    background-color: #CCCCCC;
  }
  
  .row-bg{
    border-radius: 10px 0 0 10px;
    margin-left: 10px;
  }
  .item-buttom{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .item-row-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  