.nav-main{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transition: width 0.3s ease;
    background-color: white;
  }
  .nav-main.active{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 50px;
  }
  .nav-header{
    display: flex;
    justify-content: center;
  }
  .nav-header-image{
    margin-top: 1rem;
    height: 35px;
    justify-content: center;
  }
  .nav-header-image:hover{
    background-color: #ededed;
  }
  
  .nav-items{
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    height: 100%;
  
  }
  .nav-items.active{
    width: 60px;
  }
  .nav-item:hover{
    background-color: #ededed;
  }
  .nav-item{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
  }
  .nav-item-image{
    height:25px;
  }
  .nav-item-label{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 4px;
    color: black;
  }
  
  .nav-item-footer:hover{
    background-color: #ededed;
  }
  .nav-item-footer{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
  }
  .nav-item-image-footer{
    height: 25px;
  }
  .nav-item-label-footer{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 4px;
    color: black;
  }