.whiteBG{
  background-color: white;
}
.whiteSmokeBG{
  background-color: #CCCCCC;
}
.silverBG{
  background-color: #ededed;
}
.greenButton{
  background-color: green;
}

.redButton{
  background-color: rgb(150, 1, 1);
}

.yellowButton{
  background-color: rgb(156, 156, 0);
}